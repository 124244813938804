import { fork, put, select, take, takeEvery } from "redux-saga/effects";

import { showMessageToast } from "components/toasts/MessageToast";
import { callApi } from "sagas/helpers/api";
import {
  selectEnvironment,
  setError,
  setSuccess,
  startLoading,
} from "store/appSlice";

import * as RiskRulesApi from "../../api/RiskRules";
import {
  createRiskRule,
  deleteRiskRule,
  fetchRiskRules,
  fetchRiskRulesSuccess,
  insertDefaultDraftRule,
  linkDraftToRiskRule,
  removeDraftRiskRule,
  removeRiskRule,
  replaceRiskRule,
  RiskRulesOperationPaths,
  selectRiskRules,
  setRiskRules,
  updateRiskRule,
} from "./riskRules.slice";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { APPLICATION_ENV } from "constants/environment";
import type { ValidationError } from "models/ValidationError";
import type { OperationPath } from "store/appSlice";
import type { UUID } from "utils";

import type {
  RiskRule,
  RiskRuleBase,
  RiskRuleDraft,
} from "../../models/RiskRule";

function handleRiskRuleValidation(error: any, genericError: string) {
  if (error.exception?.errorList?.length > 0) {
    const messages = error.exception?.errorList.map(
      (error: ValidationError) => error.message
    );

    for (const i in messages) {
      showMessageToast({ variant: "failure", bodyContent: messages[i] });
    }
  } else if (
    typeof error.exception?.message === "string" &&
    error.exception?.code === 400
  ) {
    showMessageToast({
      variant: "failure",
      bodyContent: error.exception.message,
    });
  } else {
    showMessageToast({ variant: "failure", bodyContent: genericError });
  }
}

type FetchRiskRulesPayloadAction = PayloadAction<{
  customerId: UUID;
  overrideOperationPath?: false | OperationPath;
}>;

function* fetchRiskRulesSaga({
  payload: { customerId, overrideOperationPath },
}: FetchRiskRulesPayloadAction) {
  const { list: listOperationPath } = RiskRulesOperationPaths;

  const operationPath = overrideOperationPath ?? listOperationPath;

  if (overrideOperationPath !== false) {
    yield put(startLoading(operationPath));
  }

  try {
    const appEnvironment: APPLICATION_ENV = yield select(selectEnvironment);

    const response: RiskRule[] = yield callApi(
      RiskRulesApi.getRiskRules,
      customerId
    );

    yield put(setRiskRules(response));

    if (!response.length) {
      yield put(insertDefaultDraftRule({ environment: appEnvironment }));
    }

    if (overrideOperationPath !== false) {
      yield put(setSuccess(operationPath));
    }

    yield put(fetchRiskRulesSuccess());
  } catch (error) {
    console.error(error);
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

const cleanupFilters = (
  filters: RiskRuleBase["filter"]
): RiskRuleBase["filter"] => {
  const filterObject = Object.fromEntries(
    Object.entries(filters).filter((property) => {
      const [, value] = property;

      return value && value.values.length > 0;
    })
  );

  if (filterObject.currency === undefined) {
    filterObject.currency = null;
  }

  if (filterObject.country === undefined) {
    filterObject.country = null;
  }

  return filterObject;
};

function* createRiskRuleSaga({
  payload: { customerId, data },
}: PayloadAction<{ customerId: UUID; data: RiskRuleDraft }>) {
  const { create: operationPathPrefix } = RiskRulesOperationPaths;
  const appEnvironment: APPLICATION_ENV = yield select(selectEnvironment);
  const operationPath = [...operationPathPrefix, data.key];
  const preparedData = {
    ...data,
    filter: cleanupFilters(data.filter),
  };

  yield put(startLoading(operationPath));

  try {
    const response: Pick<RiskRule, "id"> = yield callApi(
      RiskRulesApi.postRiskRule,
      customerId,
      preparedData
    );
    const savedOperationPath = [...operationPathPrefix, response.id];

    yield put(startLoading(savedOperationPath));

    yield put(setSuccess(operationPath));
    showMessageToast({
      bodyContent: "Risk Rule has been activated.",
      variant: "success",
    });
    yield put(
      linkDraftToRiskRule({
        environment: appEnvironment,
        key: preparedData.key ?? "",
        id: response.id,
      })
    );
    yield put(
      fetchRiskRules({
        customerId: customerId,
        overrideOperationPath: false,
      })
    );
    yield take(fetchRiskRulesSuccess.type);
    yield put(setSuccess(savedOperationPath));
  } catch (error) {
    handleRiskRuleValidation(error, "Creating rule failed!");

    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* updateRiskRuleSaga({
  payload: { customerId, data },
}: PayloadAction<{ customerId: UUID; data: RiskRule }>) {
  const { update: operationPathPrefix } = RiskRulesOperationPaths;
  const operationPath = [...operationPathPrefix, data.id];
  const preparedData = {
    ...data,
    filter: cleanupFilters(data.filter),
  };

  yield put(startLoading(operationPath));

  try {
    const response: Pick<RiskRule, "id"> = yield callApi(
      RiskRulesApi.putRiskRule,
      customerId,
      preparedData
    );

    const state: RiskRule[] = yield select(selectRiskRules);
    const oldState = state.find(({ id }) => id === response.id);

    yield put(replaceRiskRule(preparedData));
    yield put(
      fetchRiskRules({
        customerId: customerId,
        overrideOperationPath: false,
      })
    );
    yield take(fetchRiskRulesSuccess.type);
    yield put(setSuccess(operationPath));

    if (oldState && !oldState.active && preparedData.active) {
      showMessageToast({
        bodyContent: "Risk Rule has been activated.",
        variant: "success",
      });
    } else if (oldState?.active && !preparedData.active) {
      showMessageToast({
        bodyContent: "Risk Rule has been deactivated.",
        variant: "success",
      });
    }
  } catch (error) {
    handleRiskRuleValidation(error, "Updating rule failed!");
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* removeRiskRuleSaga({
  payload: { customerId, ruleId, draftKey },
}: PayloadAction<{ customerId: UUID; ruleId: UUID; draftKey?: string }>) {
  const { remove: operationPathPrefix } = RiskRulesOperationPaths;
  const operationPath = [...operationPathPrefix, ruleId];

  const appEnvironment: APPLICATION_ENV = yield select(selectEnvironment);

  yield put(startLoading(operationPath));

  try {
    yield callApi(RiskRulesApi.deleteRiskRule, customerId, ruleId);
    yield put(deleteRiskRule(ruleId));

    if (draftKey) {
      yield put(
        removeDraftRiskRule({ environment: appEnvironment, key: draftKey })
      );
    }

    yield put(setSuccess(operationPath));
    showMessageToast({
      variant: "success",
      bodyContent: "Risk Rule has been removed.",
    });
  } catch (error) {
    showMessageToast({
      variant: "failure",
      bodyContent: "Removing rule failed!",
    });
    yield put(
      setError({
        path: operationPath,
        error,
      })
    );
  }
}

function* watchFetchRiskRules() {
  yield takeEvery(fetchRiskRules.type, fetchRiskRulesSaga);
}

function* watchCreateRiskRule() {
  yield takeEvery(createRiskRule.type, createRiskRuleSaga);
}

function* watchUpdateRiskRule() {
  yield takeEvery(updateRiskRule.type, updateRiskRuleSaga);
}

function* watchRemoveRiskRule() {
  yield takeEvery(removeRiskRule.type, removeRiskRuleSaga);
}

export function* riskRulesSaga() {
  yield fork(watchFetchRiskRules);
  yield fork(watchCreateRiskRule);
  yield fork(watchUpdateRiskRule);
  yield fork(watchRemoveRiskRule);
}
