import { COMMON_TRANSLATION_KEYS } from "constants/commonTranslationsKeys";
import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_PAYMENT } from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";
import { payByLinkRoutes } from "./payByLink";

import type { Route } from "routes/RouteModel";

const PaymentList = lazyRetryWithPreload(
  () => import("../pages/List"),
  "PaymentList"
);

const PaymentPage = lazyRetryWithPreload(
  () => import("../pages/payment"),
  "PaymentPage"
);

export const transactionsRoutes: Route = {
  title: [COMMON_TRANSLATION_KEYS.payments, COMMON_TRANSLATION_KEYS.list],
  path: PAGE_URLS.list,
  newTheme: true,
  component: PaymentList,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_PAYMENT,
  },
  children: [
    ...payByLinkRoutes,
    {
      // title for this path is defined inside paymentDetails routing
      path: PAGE_URLS.details,
      newTheme: true,
      component: PaymentPage,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_PAYMENT,
      },
      hasNestedSwitch: true,
    },
  ],
};
