import {
  createAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import _get from "lodash/get";
import _set from "lodash/set";

import { ENV_SANDBOX } from "constants/environment";
import {
  ERROR_STATE,
  LOADING_STATE,
  SUCCESS_STATE,
} from "constants/operationsState";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { APPLICATION_ENV } from "constants/environment";
import type { GlobalFilteredCustomer } from "models/GlobalFilteredCustomer";
import type { UUID } from "utils";

import type { RootState } from "./index";

type OperationState =
  | { type: typeof ERROR_STATE; error?: any }
  | { type: typeof LOADING_STATE }
  | { type: typeof SUCCESS_STATE };

export type SliceState = {
  environment: APPLICATION_ENV;
  globalFilteredCustomer: GlobalFilteredCustomer | null;
  operationsState: Record<string, OperationState | null>;
  targetPathname: string | null;
};

export const initialState: SliceState = {
  environment: ENV_SANDBOX,
  globalFilteredCustomer: null,
  operationsState: {},
  targetPathname: null,
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      _set(state.operationsState, action.payload, { type: LOADING_STATE });
    },
    setError(state, { payload }) {
      const { path, error } = payload;

      _set(state.operationsState, path, { type: ERROR_STATE, error });
    },
    setSuccess(state, action) {
      _set(state.operationsState, action.payload, { type: SUCCESS_STATE });
    },
    clearOperation(state, action) {
      _set(state.operationsState, action.payload, null);
    },
    clearResult(state, action) {
      const path = action.payload;
      const operationState = _get(state.operationsState, path);

      if (
        operationState &&
        [ERROR_STATE, SUCCESS_STATE].includes(operationState.type)
      ) {
        _set(state.operationsState, path, null);
      }
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload;
    },
    setGlobalFilteredCustomer: (
      state,
      { payload }: { payload: { allIds: UUID[]; id: UUID; name: string } }
    ) => {
      state.globalFilteredCustomer = payload;
    },
    appendIdToGlobalFilteredCustomerAllIds: (
      state,
      {
        payload,
      }: { payload: { createdCustomerId: UUID; parentCustomerId: UUID | null } }
    ) => {
      const { createdCustomerId, parentCustomerId } = payload;

      if (
        parentCustomerId &&
        state.globalFilteredCustomer?.allIds.includes(parentCustomerId)
      ) {
        state.globalFilteredCustomer?.allIds.push(createdCustomerId);
      }
    },
    setTargetPathname: (state, { payload }: PayloadAction<string>) => {
      state.targetPathname = payload;
    },
    clearTargetPathname: (state) => {
      state.targetPathname = null;
    },
    clearGlobalFilteredCustomer: (state) => {
      state.globalFilteredCustomer = null;
    },
  },
});

export const {
  startLoading,
  setError,
  setSuccess,
  clearOperation,
  clearResult,
  setEnvironment,
  setGlobalFilteredCustomer,
  appendIdToGlobalFilteredCustomerAllIds,
  clearGlobalFilteredCustomer,
  setTargetPathname,
  clearTargetPathname,
} = slice.actions;

export const init = createAction("init");
export const toggleEnvironment = createAction<{ allRoutes: string[] }>(
  "toggleEnvironment"
);

export const selectEnvironment = createDraftSafeSelector(
  (state: RootState) => state.app,
  ({ environment }): APPLICATION_ENV => environment
);

export type OperationPath =
  | (string | null | undefined)[]
  | string
  | null
  | undefined;

export const selectOperationState =
  (path: OperationPath) => (state: RootState) => {
    if (
      typeof path === "string" ||
      path?.every((subpath): subpath is string => Boolean(subpath))
    ) {
      return _get(state.app.operationsState, path);
    }

    return undefined;
  };

export const selectGlobalFilteredCustomer = (state: RootState) =>
  state.app.globalFilteredCustomer;

export const selectGlobalFilteredCustomerIds = (state: RootState) =>
  state.app.globalFilteredCustomer?.allIds;

export const selectTargetPathname = (state: RootState) =>
  state.app.targetPathname;

export const appSlice = slice.reducer;
