/*eslint perfectionist/sort-objects: "error"*/

export const enGb = {
  account: "Account",
  accountDetails: "Account details",
  accountNotFound: "Account not found",
  accountNumber: "Account number",
  accountOwner: "Account owner",
  accounts: "Accounts",
  accountType: "Account type",
  amount: "Amount ({{currency}})",
  availableFunds: "Available funds",
  backToAllAccounts: "Back to all accounts",
  balance: "Balance",
  balanceCurrency: "Balance ({{currency}})",
  closedLoopPayout: "Closed loop payout",
  completed: "Completed",
  connected: "Connected",
  credit: "Credit",
  failed: "Failed",
  givenAccountCouldNotBeFound: "Given account could not be found",
  iban: "IBAN",
  noTransactions: "No transactions",
  openLoopPayout: "Open loop payout",
  payment: "Payment",
  pending: "Pending",
  reference: "Reference",
  refund: "Refund",
  reversal: "Reversal",
  settlement: "Settlement",
  sortCode: "Sort code",
  swiftCodeBic: "Swift code (BIC)",
  transactions: "Transactions",
  viewDetails: "View details",
  volt: "Volt",
  youDontHaveAnyTransactionsYet: "You don't have any transactions yet.",
};
