import {
  BankIcon,
  FingerprintIcon,
  IbanIcon,
  IdIcon,
  IpIcon,
  MailIcon,
  UserIcon,
} from "@volt_developers/components";

import { INCLUSION_STATE } from "./constants/InclusionState";
import {
  BLOCK_RULE_TYPE_BANK,
  BLOCK_RULE_TYPE_DOCUMENT_ID,
  BLOCK_RULE_TYPE_FINGERPRINT,
  BLOCK_RULE_TYPE_IBAN,
  BLOCK_RULE_TYPE_IP_ADDRESS,
  BLOCK_RULE_TYPE_MAIL,
  BLOCK_RULE_TYPE_PAYER,
} from "./constants/Rules";

import type { ReactNode } from "react";

import type { BlockRuleType } from "./models/BlockRules";
import type { RiskRuleFiltersOptions } from "./models/RiskRule";

export const BLOCKLIST_ICONS: Record<BlockRuleType, ReactNode> = {
  [BLOCK_RULE_TYPE_BANK]: <BankIcon />,
  [BLOCK_RULE_TYPE_FINGERPRINT]: <FingerprintIcon />,
  [BLOCK_RULE_TYPE_DOCUMENT_ID]: <IdIcon />,
  [BLOCK_RULE_TYPE_MAIL]: <MailIcon />,
  [BLOCK_RULE_TYPE_IBAN]: <IbanIcon />,
  [BLOCK_RULE_TYPE_IP_ADDRESS]: <IpIcon />,
  [BLOCK_RULE_TYPE_PAYER]: <UserIcon />,
};

export const DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_TYPE = BLOCK_RULE_TYPE_BANK;
export const DEFAULT_CIRCUIT_BREAKER_NEW_BLOCK_RULE_ACTIVE_FLAG = true;
export const VOLT_CONNECT_LINK = "https://www.volt.io/products/connect";

export const BLOCKLIST_COLUMNS = [
  {
    key: "type",
    label: "",
    isSortable: false,
    width: 32,
    padding: "none" as const,
  },
  {
    key: "value",
    label: "value",
    // TODO temporarily disabled due to API issues
    isSortable: false,
    width: "40%",
  },
  {
    key: "userId",
    label: "blocked by",
    isSortable: true,
    width: "40%",
  },
  {
    key: "blockedAt",
    label: "at",
    isSortable: true,
  },
];

export const RISK_RULES_DEFAULT_FILTER_OPTIONS: RiskRuleFiltersOptions = {
  currency: {
    hasInclusion: false,
    hasSearch: true,
    inclusion: INCLUSION_STATE.INCLUDE,
  },
  country: {
    hasInclusion: true,
    hasSearch: true,
    inclusion: INCLUSION_STATE.INCLUDE,
  },
};
